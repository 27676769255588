




































































import Component from 'vue-class-component';
import Vue from 'vue';
import RsOnboardingHeader from '@/modules/onboarding/components/main/header.vue';
import {OnboardingActions} from '@/modules/onboarding/store/actions';
import {OnboardingRouter} from '@/modules/onboarding/router';
import {DashboardRouter} from '@/modules/dashboard/router';
import DashboardModule from '@/modules/dashboard';
import {AUTH_SERVICE, AuthData} from '@/modules/onboarding/services/auth.service';
import {Action, Mutation} from 'vuex-class';
import OnboardingModule from '@/modules/onboarding';
import {Icons} from '@/shared/components/icon/icons';
import RsIcon from '@/shared/components/icon/icon.vue';
import {OnboardingMutations} from '@/modules/onboarding/store/mutations';
import RsButton from '@/shared/components/buttons/button.vue';
import {LOCALSTORAGE} from '@/shared/constants/LOCALSTORAGE';
import {JobsRouter} from '@/modules/dashboard/modules/jobs/router';
import {JobsActions} from '@/modules/dashboard/modules/jobs/store/actions';
import JobsModule from '@/modules/dashboard/modules/jobs';
import {ONBOARDING_STATE} from '@/shared/constants/ONBOARDING_STATE';
import {IApiTalent} from '@/shared/models/IApiTalent';

@Component({
  metaInfo: {
    title: 'Logging you in...'
  },
  components: {RsButton, RsIcon, RsOnboardingHeader},
})
export default class OnboardingAuth extends Vue {
  @Action(OnboardingActions.PROCESS_LOGIN, {namespace: OnboardingModule.namespace})
  processLogin: (authData: AuthData | null) => Promise<IApiTalent>;
  @Action(OnboardingActions.LOGOUT, {namespace: OnboardingModule.namespace})
  logout: () => void;
  @Action(JobsActions.APPLY_FOR_JOB, {namespace: `${DashboardModule.namespace}/${JobsModule.namespace}`})
  applyForJob: (jobId: string) => Promise<void>;
  @Mutation(OnboardingMutations.LOGOUT, {namespace: OnboardingModule.namespace})
  logoutUser: () => void;

  Icons = Icons;
  OnboardingRouter = OnboardingRouter;

  signup = false;

  error = false;

  public async created() {
    const signup = localStorage.getItem(LOCALSTORAGE.SIGNUP);
    if (signup) {
      this.signup = true;
      localStorage.removeItem(LOCALSTORAGE.SIGNUP);
    } else {
      this.signup = false;
    }
    AUTH_SERVICE.handleAuth()
      .then(() => {
        this.processLogin(AUTH_SERVICE.authData())
          .then((user: IApiTalent) => {
            console.log(user);
            this.$track.sendEvent('Login')
            const jobId = localStorage.getItem(LOCALSTORAGE.JOB_APPLY)
            if (jobId) {
              localStorage.removeItem(LOCALSTORAGE.JOB_APPLY);
              this.applyForJob(jobId)
                .then(() => {
                  this.$track.sendEvent('JobApply', {id: jobId})
                  this.$router.push({name: JobsRouter.SINGLE, params: {id: jobId}});
                })
                .catch(() => {
                  if (!user?.uiContext?.onboardingStatus || user.uiContext?.onboardingStatus === ONBOARDING_STATE.ROLES) {
                    this.$router.push({name: OnboardingRouter.PREFERRED_ROLES});
                  } else if(user.uiContext?.onboardingStatus === ONBOARDING_STATE.WELCOME) {
                    this.$router.push({name: OnboardingRouter.WELCOME});
                  } else if(user.uiContext?.onboardingStatus === ONBOARDING_STATE.HOW_IT_WORKS) {
                    this.$router.push({name: OnboardingRouter.HOW_IT_WORKS});
                  } else {
                    this.$router.push({name: DashboardRouter.INDEX});
                  }
                  this.$router.push({name: DashboardRouter.INDEX});
                })
            } else {
              this.$router.push({name: DashboardRouter.INDEX});
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$router.push({name: OnboardingRouter.LOGIN});
            } else {
              this.$router.push({name: OnboardingRouter.ONBOARDING});
            }
          })
      })
      .catch(() => {
        this.error = true;
        this.logoutUser();
      })
  }
}
